import Joi from 'joi';
import { isEmpty, isValidJoiSchema } from 'utils/validation';

export interface RegisterValidationErrors {
  firstName?: string;
  surname?: string;
  email?: string;
  password?: string;
  confirmPassword?: string;
}

export function validateRegisterNameInput(nameInput: string): string | undefined {
  return isEmpty(nameInput) ? 'Name field is required' : undefined;
}

export function validateRegisterEmailInput(emailInput: string): string | undefined {
  const emailSchema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
  });

  if (isEmpty(emailInput)) {
    return 'Email field is required';
  }

  if (!isValidJoiSchema(emailSchema, { email: emailInput })) {
    return 'Email format is invalid';
  }

  return undefined;
}

export function validateRegisterPasswordInput(passwordInput: string): string | undefined {
  return isEmpty(passwordInput) ? 'Password field is required' : undefined;
}

export function validateRegisterConfirmPasswordInput(
  passwordInput: string,
  confirmPasswordInput: string
): string | undefined {
  if (isEmpty(confirmPasswordInput)) {
    return 'Confirm password field is required';
  }

  if (passwordInput !== confirmPasswordInput) {
    return 'Passwords do not match';
  }

  return undefined;
}
