import React from 'react';
import './MainSectionContainer.scss';

interface MainSectionContainerProps {
  backgroundColor: 'dark-blue' | 'dark-cyan' | 'white';
  fullHeight?: boolean;
  children: React.ReactNode;
}

export const MainSectionContainer = ({
  backgroundColor,
  fullHeight,
  children
}: MainSectionContainerProps): JSX.Element => (
  <div className={`main-section-container ${backgroundColor} ${fullHeight ? 'full-height' : ''}`}>
    {children}
  </div>
);

MainSectionContainer.defaultProps = {
  fullHeight: false
};
