import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { loginUserRequest } from 'redux/slices/identitySlice';
import { useAppSelector, useThunkDispatch } from 'redux/hooks';
import { LoginData } from 'models/requests/Login';
import {
  LoginValidationErrors,
  validateLoginEmailInput,
  validateLoginPasswordInput
} from 'services/loginValidation';
import { InputTextField } from 'components/InputTextField';
import { FormWrap } from 'components/FormWrap';

export const LoginForm = (): JSX.Element => {
  const [loginData, setLoginData] = useState<LoginData>({
    email: '',
    password: ''
  });

  const [loginErrors, setLoginErrors] = useState<LoginValidationErrors>({
    email: undefined,
    password: undefined
  });

  const isAuthenticated = useAppSelector((state) => state.identity.isAuthenticated);
  const loginStatus = useAppSelector((state) => state.identity.statuses.login);
  const dispatchThunk = useThunkDispatch();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard'); // push user to dashboard when they login successfully
    }
  }, [isAuthenticated]);

  const onChangeEmail = (event: React.FormEvent<HTMLInputElement>) => {
    setLoginData({ ...loginData, email: event.currentTarget.value });
    setLoginErrors({ ...loginErrors, email: validateLoginEmailInput(event.currentTarget.value) });
  };

  const onChangePassword = (event: React.FormEvent<HTMLInputElement>) => {
    setLoginData({ ...loginData, password: event.currentTarget.value });
    setLoginErrors({
      ...loginErrors,
      password: validateLoginPasswordInput(event.currentTarget.value)
    });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatchThunk(loginUserRequest(loginData));
    // redirect handled by isAuthenticated useEffect, don't need to pass history
  };

  return (
    <FormWrap buttonText="Login" onSubmit={onSubmit} isSubmitting={loginStatus === 'pending'}>
      <InputTextField
        id="login-email-field"
        type="email"
        label="Email Address"
        value={loginData.email}
        error={loginErrors.email}
        onChange={onChangeEmail}
        onBlur={onChangeEmail}
      />
      <InputTextField
        id="login-password-field"
        type="password"
        label="Password"
        value={loginData.password}
        error={loginErrors.password}
        onChange={onChangePassword}
        onBlur={onChangePassword}
      />
    </FormWrap>
  );
};
