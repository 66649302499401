/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IdentityState } from 'models/IdentityState';
import { LoginData } from 'models/requests/Login';
import { RegisterData } from 'models/requests/Register';
import { User } from 'models/User';
import {
  sendCreateList,
  sendCreateTodo,
  sendDeleteList,
  sendDeleteTodo,
  sendGetProfile,
  sendLoginUser,
  sendLogoutUser,
  sendRegisterUser
} from 'services/apiClient';
import { AppThunkResult } from 'redux/store';
import {
  CreateListData,
  CreateTodoData,
  DeleteListData,
  DeleteTodoData
} from 'models/requests/Todo';
import { List, Todo } from 'models/List';

const initialState: IdentityState = {
  statuses: {
    login: 'idle',
    logout: 'idle',
    register: 'idle',
    getProfile: 'idle',
    createTodo: 'idle',
    deleteTodo: 'idle',
    createList: 'idle',
    deleteList: 'idle'
  },
  isAuthenticated: false,
  user: undefined
};

// createSlice converts to immutable updates
const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    loginUserPending: (state) => {
      state.statuses.login = 'pending';
      state.user = undefined;
      state.isAuthenticated = false;
    },
    loginUserSucceeded: (state, action: PayloadAction<User>) => {
      state.statuses.login = 'succeeded';
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    loginUserFailed: (state) => {
      state.statuses.login = 'failed';
      state.user = undefined;
      state.isAuthenticated = false;
    },
    logoutUserPending: (state) => {
      state.statuses.logout = 'pending';
    },
    logoutUserSucceeded: (state) => {
      state.statuses.logout = 'succeeded';
      state.user = undefined;
      state.isAuthenticated = false;
    },
    logoutUserFailed: (state) => {
      state.statuses.logout = 'failed';
    },
    registerUserPending: (state) => {
      state.statuses.register = 'pending';
      state.user = undefined;
      state.isAuthenticated = false;
    },
    registerUserSucceeded: (state, action: PayloadAction<User>) => {
      state.statuses.register = 'succeeded';
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    registerUserFailed: (state) => {
      state.statuses.register = 'failed';
      state.user = undefined;
      state.isAuthenticated = false;
    },
    // following three basically just used on load to check if user's cookie is good
    getUserProfilePending: (state) => {
      state.statuses.getProfile = 'pending';
      state.user = undefined;
    },
    getUserProfileSucceeded: (state, action: PayloadAction<User>) => {
      state.statuses.getProfile = 'succeeded';
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    getUserProfileFailed: (state) => {
      state.statuses.getProfile = 'failed';
      state.isAuthenticated = false;
      state.user = undefined;
    },
    createTodoPending: (state) => {
      state.statuses.createTodo = 'pending';
    },
    createTodoSucceeded: (state, action: PayloadAction<{ newTodo: Todo; listName: string }>) => {
      const { newTodo, listName } = action.payload;
      state.statuses.createTodo = 'succeeded';
      state.user?.lists.find((list) => list.name === listName)?.todos.push(newTodo);
    },
    createTodoFailed: (state) => {
      state.statuses.createTodo = 'failed';
    },
    deleteTodoPending: (state) => {
      state.statuses.deleteTodo = 'pending';
    },
    deleteTodoSucceeded: (
      state,
      action: PayloadAction<{ deletedTodoName: string; listName: string }>
    ) => {
      const { deletedTodoName, listName } = action.payload;
      state.statuses.deleteTodo = 'succeeded';
      const listToDeleteFrom = state.user?.lists.find((list) => list.name === listName)?.todos;
      const index = listToDeleteFrom?.findIndex((todo) => todo.title === deletedTodoName);
      listToDeleteFrom?.splice(index!, 1);
    },
    deleteTodoFailed: (state) => {
      state.statuses.deleteTodo = 'failed';
    },
    createListPending: (state) => {
      state.statuses.createList = 'pending';
    },
    createListSucceeded: (state, action: PayloadAction<{ list: List }>) => {
      const { list } = action.payload;
      state.statuses.createList = 'succeeded';
      state.user?.lists.push(list);
    },
    createListFailed: (state) => {
      state.statuses.createList = 'failed';
    },
    deleteListPending: (state) => {
      state.statuses.deleteList = 'pending';
    },
    deleteListSucceeded: (state, action: PayloadAction<{ deletedListName: string }>) => {
      const { deletedListName } = action.payload;
      state.statuses.deleteList = 'succeeded';
      const index = state.user?.lists.findIndex((list) => list.name === deletedListName);
      state.user?.lists.splice(index!, 1);
    },
    deleteListFailed: (state) => {
      state.statuses.deleteList = 'failed';
    }
  }
});

export default identitySlice.reducer;
export const {
  loginUserPending,
  loginUserSucceeded,
  loginUserFailed,
  logoutUserPending,
  logoutUserSucceeded,
  logoutUserFailed,
  registerUserPending,
  registerUserSucceeded,
  registerUserFailed,
  getUserProfilePending,
  getUserProfileSucceeded,
  getUserProfileFailed,
  createTodoPending,
  createTodoSucceeded,
  createTodoFailed,
  deleteTodoPending,
  deleteTodoSucceeded,
  deleteTodoFailed,
  createListPending,
  createListSucceeded,
  createListFailed,
  deleteListPending,
  deleteListSucceeded,
  deleteListFailed
} = identitySlice.actions;

export const loginUserRequest = (loginData: LoginData): AppThunkResult =>
  async function loginUserThunk(dispatch) {
    dispatch(loginUserPending());
    try {
      const loginResponse = await sendLoginUser(loginData); // sets cookie
      const { success } = loginResponse.data; // success not really needed anymore
      const profileResponse = await sendGetProfile();
      const { userDetails, lists } = profileResponse.data;
      dispatch(loginUserSucceeded({ userDetails, lists }));
    } catch (err: any) {
      console.log(err.response);
      dispatch(loginUserFailed());
    }
  };

export const logoutUserRequest = (): AppThunkResult =>
  async function logoutUserThunk(dispatch) {
    dispatch(logoutUserPending());
    try {
      await sendLogoutUser();
      dispatch(logoutUserSucceeded());
    } catch (err: any) {
      console.log(err.response);
      dispatch(logoutUserFailed());
    }
  };

export const registerUserRequest = (registerData: RegisterData): AppThunkResult =>
  async function registerUserThunk(dispatch) {
    dispatch(registerUserPending());
    try {
      const registerResponse = await sendRegisterUser(registerData); // sets cookie
      const { success, createdUser } = registerResponse.data;
      const profileResponse = await sendGetProfile();
      const { userDetails, lists } = profileResponse.data;
      dispatch(registerUserSucceeded({ userDetails, lists }));
    } catch (err: any) {
      console.log(err.response);
      dispatch(registerUserFailed());
    }
  };

export const createTodoRequest = (createTodoData: CreateTodoData): AppThunkResult =>
  async function createTodoThunk(dispatch) {
    dispatch(createTodoPending());
    try {
      const createTodoResponse = await sendCreateTodo(createTodoData);
      const { success, newTodo } = createTodoResponse.data;
      dispatch(createTodoSucceeded({ newTodo, listName: createTodoData.list }));
    } catch (err: any) {
      console.log(err.response);
      dispatch(createTodoFailed());
    }
  };

export const deleteTodoRequest = (deleteTodoData: DeleteTodoData): AppThunkResult =>
  async function deleteTodoThunk(dispatch) {
    dispatch(deleteTodoPending());
    try {
      const deleteTodoResponse = await sendDeleteTodo(deleteTodoData);
      dispatch(
        deleteTodoSucceeded({
          deletedTodoName: deleteTodoData.todoName,
          listName: deleteTodoData.list
        })
      );
    } catch (err: any) {
      console.log(err.response);
      dispatch(deleteTodoFailed());
    }
  };

export const createListRequest = (createListData: CreateListData): AppThunkResult =>
  async function createListThunk(dispatch) {
    dispatch(createListPending());
    try {
      const createListResponse = await sendCreateList(createListData);
      const { success, list } = createListResponse.data;
      dispatch(createListSucceeded({ list: list! }));
    } catch (err: any) {
      console.log(err.response);
      dispatch(createListFailed());
    }
  };

export const deleteListRequest = (deleteListData: DeleteListData): AppThunkResult =>
  async function deleteListThunk(dispatch) {
    dispatch(deleteListPending());
    try {
      const deleteListResponse = await sendDeleteList(deleteListData);
      dispatch(
        deleteListSucceeded({
          deletedListName: deleteListData.name
        })
      );
    } catch (err: any) {
      console.log(err.response);
      dispatch(deleteListFailed());
    }
  };

// basically just used on load to check if user's cookie is good
export const getUserProfileRequest = (): AppThunkResult =>
  async function getUserProfileThunk(dispatch) {
    dispatch(getUserProfilePending());
    try {
      const profileResponse = await sendGetProfile();
      const { userDetails, lists } = profileResponse.data;
      dispatch(getUserProfileSucceeded({ userDetails, lists }));
    } catch (err: any) {
      console.log(err.response);
      dispatch(getUserProfileFailed());
    }
  };
