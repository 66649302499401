import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RegisterData } from 'models/requests/Register';
import { useAppSelector, useThunkDispatch } from 'redux/hooks';
import { registerUserRequest } from 'redux/slices/identitySlice';
import {
  RegisterValidationErrors,
  validateRegisterConfirmPasswordInput,
  validateRegisterEmailInput,
  validateRegisterNameInput,
  validateRegisterPasswordInput
} from 'services/registerValidation';
import { FormWrap } from 'components/FormWrap';
import { InputTextField } from 'components/InputTextField';

export const RegisterForm = (): JSX.Element => {
  const [registerData, setRegisterData] = useState<RegisterData>({
    firstname: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [registerErrors, setRegisterErrors] = useState<RegisterValidationErrors>({
    firstName: undefined,
    surname: undefined,
    email: undefined,
    password: undefined,
    confirmPassword: undefined
  });

  const isAuthenticated = useAppSelector((state) => state.identity.isAuthenticated);
  const registerStatus = useAppSelector((state) => state.identity.statuses.register);
  const dispatch = useThunkDispatch();
  const history = useHistory();

  // If logged in and user navigates to Register page, redirect them to dashboard
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  });

  const onChangeFirstName = (event: React.FormEvent<HTMLInputElement>) => {
    setRegisterData({ ...registerData, firstname: event.currentTarget.value });
    setRegisterErrors({
      ...registerErrors,
      firstName: validateRegisterNameInput(event.currentTarget.value)
    });
  };

  const onChangeSurname = (event: React.FormEvent<HTMLInputElement>) => {
    setRegisterData({ ...registerData, surname: event.currentTarget.value });
    setRegisterErrors({
      ...registerErrors,
      surname: validateRegisterNameInput(event.currentTarget.value)
    });
  };

  const onChangeEmail = (event: React.FormEvent<HTMLInputElement>) => {
    setRegisterData({ ...registerData, email: event.currentTarget.value });
    setRegisterErrors({
      ...registerErrors,
      email: validateRegisterEmailInput(event.currentTarget.value)
    });
  };

  const onChangePassword = (event: React.FormEvent<HTMLInputElement>) => {
    setRegisterData({ ...registerData, password: event.currentTarget.value });
    setRegisterErrors({
      ...registerErrors,
      password: validateRegisterPasswordInput(event.currentTarget.value)
    });
  };

  const onChangeConfirmPassword = (event: React.FormEvent<HTMLInputElement>) => {
    setRegisterData({ ...registerData, confirmPassword: event.currentTarget.value });
    setRegisterErrors({
      ...registerErrors,
      confirmPassword: validateRegisterConfirmPasswordInput(
        registerData.password,
        event.currentTarget.value
      )
    });
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(registerUserRequest(registerData));
  };

  return (
    <FormWrap buttonText="Register" onSubmit={onSubmit} isSubmitting={registerStatus === 'pending'}>
      <InputTextField
        id="register-firstname-field"
        type="text"
        label="First Name"
        value={registerData.firstname}
        error={registerErrors.firstName}
        onChange={onChangeFirstName}
        onBlur={onChangeFirstName}
      />
      <InputTextField
        id="register-surname-field"
        type="text"
        label="Surname"
        value={registerData.surname}
        error={registerErrors.surname}
        onChange={onChangeSurname}
        onBlur={onChangeSurname}
      />
      <InputTextField
        id="register-email-field"
        type="email"
        label="Email Address"
        value={registerData.email}
        error={registerErrors.email}
        onChange={onChangeEmail}
        onBlur={onChangeEmail}
      />
      <InputTextField
        id="register-password-field"
        type="password"
        label="Password"
        value={registerData.password}
        error={registerErrors.password}
        onChange={onChangePassword}
        onBlur={onChangePassword}
      />
      <InputTextField
        id="register-confirm-password-field"
        type="password"
        label="Confirm Password"
        value={registerData.confirmPassword}
        error={registerErrors.confirmPassword}
        onChange={onChangeConfirmPassword}
        onBlur={onChangeConfirmPassword}
      />
    </FormWrap>
  );
};
