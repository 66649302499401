import { Todo } from 'models/List';
import React from 'react';
import { useThunkDispatch } from 'redux/hooks';
import { deleteTodoRequest } from 'redux/slices/identitySlice';
import './Tasks.scss';

interface TaskItemProps {
  task: Todo;
  listName: string;
}

export const TaskItem = ({ task, listName }: TaskItemProps): JSX.Element => {
  const dispatchThunk = useThunkDispatch();

  const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatchThunk(deleteTodoRequest({ list: listName, todoName: task.title }));
  };

  return (
    <div className="row my-2">
      <div className="col">
        <div className="task-name-item">
          <div className="row mx-0">
            <div className="col-10 px-0">
              <div className="task-name">
                <p>{task.title}</p>
              </div>
            </div>
            <div className="col-2 px-0">
              <div className="task-name-delete-button">
                <button type="button" onClick={onDeleteClick}>
                  <i className="bi-trash" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
