import { List } from 'models/List';
import React, { Dispatch, SetStateAction } from 'react';
import { useThunkDispatch } from 'redux/hooks';
import { deleteListRequest } from 'redux/slices/identitySlice';
import './TaskLists.scss';

interface TaskListItemProps {
  list: List;
  setActiveList: Dispatch<SetStateAction<List | undefined>>;
}

export const TaskListItem = ({ list, setActiveList }: TaskListItemProps): JSX.Element => {
  const onTaskListItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setActiveList(list);
  };

  const isMainTaskList = list.name === 'Tasks';
  const dispatchThunk = useThunkDispatch();

  const onDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatchThunk(deleteListRequest({ name: list.name }));
  };

  return (
    <div className="row my-2 mx-0">
      <div className="col">
        <div className="list-name-item">
          <div className="row mx-0">
            <div className="col-10 px-0">
              <div className="list-name">
                <button type="button" onClick={onTaskListItemClick}>
                  {list.name}
                </button>
              </div>
            </div>
            <div className="col-2 px-0">
              {!isMainTaskList && (
                <div className="list-name-delete-button">
                  <button type="button" onClick={onDeleteClick}>
                    <i className="bi-trash" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
