/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Card } from 'components/Card';
import { MainSectionContainer } from 'components/MainSectionContainer';
import { TaskLists } from 'components/TaskLists';
import { Tasks } from 'components/Tasks';
import { List } from 'models/List';
import React, { useState } from 'react';
import { useAppSelector } from 'redux/hooks';

export const Dashboard = (): JSX.Element => {
  const user = useAppSelector((state) => state.identity.user);
  const [activeList, setActiveList] = useState<List | undefined>(
    user?.lists?.find((list) => list.name === 'Tasks')
  );
  const list = useAppSelector(
    (state) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      state.identity.user?.lists.find((l) => l.name === activeList?.name)
    // eslint-disable-next-line function-paren-newline
  );

  return (
    <MainSectionContainer backgroundColor="dark-cyan" fullHeight>
      <div className="container text-light">
        <div className="row my-3">
          <div className="col">
            <h1>Hey there, {user?.userDetails.firstname}</h1>
          </div>
        </div>
        <Card headerText={`Active list: ${activeList!.name}`} color="dark">
          <div className="row">
            <div className="col-md-3">
              <TaskLists lists={user?.lists!} setActiveList={setActiveList} />
            </div>
            <div className="col-md-9">
              <Tasks taskList={list} />
            </div>
          </div>
        </Card>
      </div>
    </MainSectionContainer>
  );
};
