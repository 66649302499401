import React from 'react';
import './Card.scss';

interface CardProps {
  headerText: string;
  color: 'dark' | 'light';
  children: React.ReactNode;
}

export const Card = ({ headerText, color, children }: CardProps): JSX.Element => (
  <div className={`todo-app-card ${color}`}>
    <div className="card-header fs-2">{headerText}</div>
    <div className="todo-app-card-body">{children}</div>
  </div>
);
