import Joi from 'joi';
import { isEmpty, isValidJoiSchema } from 'utils/validation';

export interface LoginValidationErrors {
  email?: string;
  password?: string;
}

export function validateLoginEmailInput(emailInput: string): string | undefined {
  const emailSchema = Joi.object({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
  });

  if (isEmpty(emailInput)) {
    return 'Email field is required';
  }

  if (!isValidJoiSchema(emailSchema, { email: emailInput })) {
    return 'Email format is invalid';
  }

  return undefined;
}

export function validateLoginPasswordInput(passwordInput: string): string | undefined {
  return isEmpty(passwordInput) ? 'Password field is required' : undefined;
}
