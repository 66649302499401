import { InputTextField } from 'components/InputTextField';
import React, { useState } from 'react';
import { useThunkDispatch } from 'redux/hooks';
import { createTodoRequest } from 'redux/slices/identitySlice';

interface AddTaskModalProps {
  buttonText: string;
  titleText: string;
  listName: string;
}

export const AddTaskModal = ({
  buttonText,
  titleText,
  listName
}: AddTaskModalProps): JSX.Element => {
  const [taskName, setTaskName] = useState<string>('');
  const dispatchThunk = useThunkDispatch();

  const onChangeTaskName = (event: React.FormEvent<HTMLInputElement>) => {
    setTaskName(event.currentTarget.value);
  };

  const onSubmitAddTaskClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatchThunk(createTodoRequest({ list: listName, title: taskName }));
    setTaskName('');
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-success"
        data-bs-toggle="modal"
        data-bs-target="#addTaskModal"
      >
        {buttonText}
      </button>

      <div
        className="modal fade text-dark"
        id="addTaskModal"
        aria-labelledby="addTaskModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addTaskModalLabel">
                {titleText}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <InputTextField
                id="add-task-name-field"
                type="text"
                label="Task Name"
                value={taskName}
                error={undefined}
                onChange={onChangeTaskName}
                onBlur={onChangeTaskName}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={onSubmitAddTaskClick}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
