import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Dashboard } from 'components/Dashboard';
import { Landing } from 'components/Landing';
import { Login } from 'components/Login';
import { Navbar } from 'components/Navbar';
import { PrivateRoute } from 'components/PrivateRoute';
import { Register } from 'components/Register';
import './App.scss';
import { useAppSelector, useThunkDispatch } from 'redux/hooks';
import { getUserProfileRequest } from 'redux/slices/identitySlice';

function App(): JSX.Element {
  const isAuthenticated = useAppSelector((state) => state.identity.isAuthenticated);
  const history = useHistory();
  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(getUserProfileRequest());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard'); // push user to dashboard if they are already logged in
    }
  }, [isAuthenticated]);

  return (
    <div className="App">
      <Navbar />
      <div className="container-fluid px-0 under-nav">
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          {/* <Route path="/404" component={NotFoundPage} /> */}
          {/* TODO: Implement a NotFoundPage */}
          <Redirect to="/404" />
        </Switch>
      </div>
    </div>
  );
}

export default App;
