import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppSelector, useThunkDispatch } from 'redux/hooks';
import { logoutUserRequest } from 'redux/slices/identitySlice';
import './Navbar.scss';

export const Navbar = (): JSX.Element => {
  const isAuthenticated = useAppSelector((state) => state.identity.isAuthenticated);
  const dispatchThunk = useThunkDispatch();
  const history = useHistory();

  const onLogoutClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatchThunk(logoutUserRequest());
    history.push('/');
  };

  return (
    <nav className="navbar navbar-dark bg-dark navbar-expand-md">
      <div className="container-fluid">
        <span className="navbar-brand fs-1 ms-2 fw-bold">ASH TULETT</span>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#todoAppNavbar"
          aria-controls="todoAppNavbar"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="todoAppNavbar">
          <ul className="navbar-nav me-auto">
            <li className="todo-app-nav-item">
              <Link to="/">Home</Link>
            </li>
            {isAuthenticated && (
              <li className="todo-app-nav-item">
                <Link to="/dashboard">Dashboard</Link>
              </li>
            )}
            {!isAuthenticated && (
              <li className="todo-app-nav-item">
                <Link to="/login">Login</Link>
              </li>
            )}
            {!isAuthenticated && (
              <li className="todo-app-nav-item">
                <Link to="/register">Register</Link>
              </li>
            )}
            {isAuthenticated && (
              <li className="todo-app-nav-item">
                <button type="button" onClick={onLogoutClick}>
                  Logout
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
