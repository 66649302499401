/* eslint-disable import/prefer-default-export */
import axios, { AxiosResponse } from 'axios';
import { LoginData, LoginResponse } from 'models/requests/Login';
import { ProfileResponse } from 'models/requests/Profile';
import { RegisterData, RegisterResponse } from 'models/requests/Register';
import {
  CreateListData,
  CreateListResponse,
  CreateTodoData,
  CreateTodoResponse,
  DeleteListData,
  DeleteListResponse,
  DeleteTodoData,
  DeleteTodoResponse
} from 'models/requests/Todo';

// eslint-disable-next-line operator-linebreak
const apiBaseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://todoappbackendalb.ashtulett.com.au'
    : 'http://localhost:5000';

export const sendLoginUser = async (data: LoginData): Promise<AxiosResponse<LoginResponse>> => {
  const response = await axios.post<LoginResponse>(`${apiBaseURL}/api/login`, data, {
    withCredentials: true
  });
  return response;
};

export const sendLogoutUser = async (): Promise<AxiosResponse> => {
  const response = await axios.post(
    `${apiBaseURL}/api/logout`,
    {},
    {
      withCredentials: true
    }
  );
  return response;
};

export const sendRegisterUser = async (
  data: RegisterData
): Promise<AxiosResponse<RegisterResponse>> => {
  const response = await axios.post<RegisterResponse>(`${apiBaseURL}/api/register`, data, {
    withCredentials: true
  });
  return response;
};

export const sendGetProfile = async (): Promise<AxiosResponse<ProfileResponse>> => {
  const response = await axios.get<ProfileResponse>(`${apiBaseURL}/api/profile`, {
    withCredentials: true
  });
  return response;
};

export const sendCreateTodo = async (
  data: CreateTodoData
): Promise<AxiosResponse<CreateTodoResponse>> => {
  const response = await axios.post<CreateTodoResponse>(`${apiBaseURL}/api/todos`, data, {
    withCredentials: true
  });
  return response;
};

export const sendDeleteTodo = async (
  data: DeleteTodoData
): Promise<AxiosResponse<DeleteTodoResponse>> => {
  const response = await axios.delete<DeleteTodoResponse>(`${apiBaseURL}/api/todos`, {
    withCredentials: true,
    data
  });
  return response;
};

export const sendCreateList = async (
  data: CreateListData
): Promise<AxiosResponse<CreateListResponse>> => {
  const response = await axios.post<CreateListResponse>(`${apiBaseURL}/api/lists`, data, {
    withCredentials: true
  });
  return response;
};

export const sendDeleteList = async (
  data: DeleteListData
): Promise<AxiosResponse<DeleteListResponse>> => {
  const response = await axios.delete<DeleteListResponse>(`${apiBaseURL}/api/lists`, {
    withCredentials: true,
    data
  });
  return response;
};
