import React from 'react';
import { Link } from 'react-router-dom';
import { LoginForm } from 'components/Login/LoginForm';
import { MainSectionContainer } from 'components/MainSectionContainer';
import { LogRegWrapper } from 'components/LogRegWrapper';

export const Login = (): JSX.Element => (
  <MainSectionContainer backgroundColor="dark-blue" fullHeight>
    <LogRegWrapper headerText="Login below">
      <Link to="/" className="btn btn-outline-primary me-3">
        Back to home
        <i className="bi-arrow-left-square ms-2" />
      </Link>
      <Link to="/register" className="btn btn-outline-primary">
        Register (New User)
        <i className="bi-briefcase ms-2" />
      </Link>
      <div className="row">
        <div className="col-md-6">
          <LoginForm />
        </div>
      </div>
    </LogRegWrapper>
  </MainSectionContainer>
);
