import React from 'react';

interface FormWrapProps {
  buttonText: string;
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  isSubmitting: boolean;
  children: React.ReactNode;
}

export const FormWrap = ({
  buttonText,
  onSubmit,
  isSubmitting,
  children: fields
}: FormWrapProps): JSX.Element => (
  <form noValidate onSubmit={onSubmit}>
    {React.Children.map(fields, (field) => (
      <div className="row">
        <div className="col">{field}</div>
      </div>
    ))}
    <div className="row">
      <div className="col-sm-3 col-md-6">
        <button type="submit" className="btn btn-primary">
          {buttonText}
        </button>
      </div>
      <div className="col">
        {isSubmitting && <div className="spinner-border m-2" role="status" />}
      </div>
    </div>
  </form>
);
