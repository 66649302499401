import { InputTextField } from 'components/InputTextField';
import React, { useState } from 'react';
import { useThunkDispatch } from 'redux/hooks';
import { createListRequest } from 'redux/slices/identitySlice';

interface AddListModalProps {
  buttonText: string;
  titleText: string;
}

export const AddListModal = ({ buttonText, titleText }: AddListModalProps): JSX.Element => {
  const [listName, setListName] = useState<string>('');
  const dispatchThunk = useThunkDispatch();

  const onChangeListName = (event: React.FormEvent<HTMLInputElement>) => {
    setListName(event.currentTarget.value);
  };

  const onSubmitAddListClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatchThunk(createListRequest({ name: listName }));
    setListName('');
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-success"
        data-bs-toggle="modal"
        data-bs-target="#addListModal"
      >
        {buttonText}
      </button>

      <div
        className="modal fade text-dark"
        id="addListModal"
        aria-labelledby="addListModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addListModalLabel">
                {titleText}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <InputTextField
                id="add-list-name-field"
                type="text"
                label="List Name"
                value={listName}
                error={undefined}
                onChange={onChangeListName}
                onBlur={onChangeListName}
              />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={onSubmitAddListClick}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
