import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { RegisterForm } from 'components/Register/RegisterForm';
import { MainSectionContainer } from 'components/MainSectionContainer';
import { LogRegWrapper } from 'components/LogRegWrapper';
// import { registerUser } from '../../redux/actions/authActions';

export const Register = (): JSX.Element => {
  const isAuthenticated = useAppSelector((state) => state.identity.isAuthenticated);

  //   const dispatch = useDispatch();
  const history = useHistory();

  // If logged in and user navigates to Register page, redirect them to dashboard
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/dashboard');
    }
  });

  return (
    <MainSectionContainer backgroundColor="dark-blue" fullHeight>
      <LogRegWrapper headerText="Register below">
        <Link to="/" className="btn btn-outline-primary me-3">
          Back to home
          <i className="bi-arrow-left-square ms-2" />
        </Link>
        <Link to="/login" className="btn btn-outline-primary">
          Login (Existing User)
          <i className="bi-box-arrow-in-right ms-2" />
        </Link>
        <div className="row">
          <div className="col-md-6">
            <RegisterForm />
          </div>
        </div>
      </LogRegWrapper>
    </MainSectionContainer>
  );
};
