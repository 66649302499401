/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Schema } from 'joi';

export const isEmpty = (obj: any): boolean => {
  if (
    obj === null ||
    obj === undefined ||
    (obj.length !== undefined && obj.length === 0) ||
    Object.keys(obj).length === 0
  ) {
    return true;
  }

  return false;
};

export const isValidJoiSchema = (schema: Schema, data: any): boolean => {
  const result = schema.validate(data, { stripUnknown: true });

  if (result.error) {
    return false;
  }

  return true;
};
