import React from 'react';

interface InputTextFieldProps {
  id: string;
  type: string;
  label: string;
  value: string;
  error: string | undefined;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FormEvent<HTMLInputElement>) => void;
}

export const InputTextField = ({
  id,
  type,
  label,
  value,
  error,
  onChange,
  onBlur
}: InputTextFieldProps): JSX.Element => (
  <div className="d-flex flex-column my-3">
    <label htmlFor={id}>{label}</label>
    <input onChange={onChange} onBlur={onBlur} value={value} id={id} type={type} />
    {error && <p className="text-danger mb-0">{error}</p>}
  </div>
);
