/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AddTaskModal } from 'components/AddModal';
import { List } from 'models/List';
import React from 'react';
import { TaskItem } from './TaskItem';

interface TasksProps {
  taskList: List | undefined;
}

export const Tasks = ({ taskList }: TasksProps): JSX.Element => {
  const taskItems = taskList?.todos.map((task) => (
    <TaskItem key={task.title} task={task} listName={taskList?.name!} />
  ));

  return (
    <div className="m-2">
      <div className="task-items my-2">{taskItems}</div>
      <div className="add-new-task">
        <AddTaskModal
          buttonText="Add New Task"
          titleText="Add New Task"
          listName={taskList?.name!}
        />
      </div>
    </div>
  );
};
