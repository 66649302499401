import React from 'react';
import { Card } from 'components/Card';

interface LogRegWrapperProps {
  headerText: string;
  children: React.ReactNode;
}

export const LogRegWrapper = ({ headerText, children }: LogRegWrapperProps): JSX.Element => (
  <div className="container">
    <div className="row">
      <div className="col">
        <Card headerText={headerText} color="light">
          <div className="container my-3">{children}</div>
        </Card>
      </div>
    </div>
  </div>
);
