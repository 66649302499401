import { AddListModal } from 'components/AddModal';
import { List } from 'models/List';
import React, { Dispatch, SetStateAction } from 'react';
import { TaskListItem } from './TaskListItem';

interface TaskListsProps {
  lists: List[];
  setActiveList: Dispatch<SetStateAction<List | undefined>>;
}

export const TaskLists = ({ lists, setActiveList }: TaskListsProps): JSX.Element => {
  const listItems = lists.map((list) => (
    <li key={list.name}>
      <TaskListItem list={list} setActiveList={setActiveList} />
    </li>
  ));

  return (
    <div className="m-2">
      <div className="dropdown my-2">
        <button
          className="btn btn-primary dropdown-toggle"
          type="button"
          id="selectListDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Select List
        </button>
        <ul className="dropdown-menu" aria-labelledby="selectListDropdown">
          {listItems}
        </ul>
      </div>
      <div className="add-new-list">
        <AddListModal buttonText="Add New List" titleText="Add New List" />
      </div>
    </div>
  );
};
