import React from 'react';
import { Card } from 'components/Card';
import { MainSectionContainer } from 'components/MainSectionContainer';
import './Landing.scss';

export const Landing = (): JSX.Element => (
  <>
    <MainSectionContainer backgroundColor="dark-blue">
      <div className="border rounded mx-5">
        <div className="row justify-content-center py-5">
          <div className="col-md-10 text-center text-white">
            <h1 className="display-1">Todo App</h1>
            <p className="lead">
              Basic todo list application with a user authentication system to demonstrate the MERN
              stack
            </p>
          </div>
        </div>
        <div className="row pb-5">
          <figure className="text-center text-white">
            <blockquote className="blockquote">
              <p>Never stop learning, because life never stops teaching</p>
            </blockquote>
            <figcaption className="blockquote-footer">
              An inspirational poster I saw once
            </figcaption>
          </figure>
        </div>
      </div>
    </MainSectionContainer>
    <MainSectionContainer backgroundColor="dark-cyan">
      <div className="row justify-content-center">
        <div className="col-md-10 text-center text-white">
          <h3 className="display-3">Tech Stack Info Dump</h3>
        </div>
      </div>
      <div className="row p-5 g-5">
        <div className="col-lg-4">
          <Card headerText="Frontend" color="dark">
            <ul className="landing-page-card-list">
              <li>React TypeScript</li>
              <li>Redux</li>
              <li>Bootstrap</li>
              <li>Sass</li>
            </ul>
          </Card>
        </div>
        <div className="col-lg-4">
          <Card headerText="Backend" color="dark">
            <ul className="landing-page-card-list">
              <li>Express TypeScript</li>
              <li>Passport JWT Authentication</li>
              <li>MongoDB</li>
            </ul>
          </Card>
        </div>
        <div className="col-lg-4">
          <Card headerText="Infra (AWS)" color="dark">
            <ul className="landing-page-card-list">
              <li>EC2 Auto Scaling Group and Load Balancer</li>
              <li>Route 53 and AWS Certificate Manager</li>
              <li>Code Pipeline</li>
              <li>Managed by CloudFormation Stack</li>
            </ul>
          </Card>
        </div>
      </div>
    </MainSectionContainer>
  </>
);
