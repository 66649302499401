import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

export const PrivateRoute = (props: RouteProps): JSX.Element => {
  const isAuthenticated = useAppSelector((state) => state.identity.isAuthenticated);
  const { component } = props;

  return isAuthenticated ? <Route component={component} {...props} /> : <Redirect to="/login" />;
};
